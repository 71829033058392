@import "@/shared/ui/config/scss/_index";

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  max-width: 355px;
  width: 100%;
  max-height: 100vh;
  z-index: 250;
  left: -100%;
  transition: all 0.3s;
  @media (max-width: 950px) {
    max-width: 587px;
  }
  @media (max-width: 768px) {
    max-width: 100%;
  }

  &.active {
    left: 0;
  }

  &__close {
    position: absolute;
    top: 18px;
    right: 18px;
    empty-cells: 18px;
    height: 18px;
    cursor: pointer;
    z-index: 251;
    @media (max-width: 950px) {
      top: 25px;
      right: 25px;
      width: 28px;
      height: 28px;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__wrap {
    position: relative;
    background: #fff;
    padding-top: 30px;
    padding-left: 55px;
    padding-right: 55px;
    padding-bottom: 70px;
    width: 100%;
    height: 100%;
    max-height: 100vh;
    overflow-y: auto;
    @media (max-width: 950px) {
      padding-top: 50px;
    }
    @media (max-width: 768px) {
      padding-left: 25px;
    }
  }
}

.burger {
  border: none;
  background-color: transparent;
  // width: 36px;
  // height: 20px;
  padding: 0;
  cursor: pointer;
  outline: none;
}

body._lock::after {
  content: " ";
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(255, 208, 77, 0.6);
  z-index: 200;
}
