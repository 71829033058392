@import "@/shared/ui/config/scss/_index";

.button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 24px;
  border-radius: 24px;
  background: linear-gradient(
    267deg,
    $color-primary-hover 3.37%,
    $color-primary 106.43%
  );
  color: $color-text-inverse;
  font-size: 20px;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  border: none;
  z-index: 0;
  width: max-content;

  @media (max-width: 950px) {
    font-size: 25px;
  }
  @media (max-width: 768px) {
    font-size: 20px;
  }

  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: $color-primary;
    opacity: 0;
    transition: opacity 0.3s;
    border-radius: 24px;
    z-index: -1;
  }

  &:hover:before {
    opacity: 1;
  }

  &:active {
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.1) 0%,
        rgba(0, 0, 0, 0.1) 100%
      ),
      linear-gradient(
        267deg,
        $color-primary-hover 3.37%,
        $color-primary 106.43%
      );
  }
}
