@import "@/shared/ui/config/scss/_index";

.popup {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 251;
  width: 100%;
  height: 100%;
  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &.open {
    display: flex;
  }

  &__wrap {
    width: 100%;
    max-width: 580px;
    border-radius: 20px;
    background: #fff;
    padding: 70px 44px 70px 44px;
    position: relative;
    box-shadow: 0px 0px 10px 0px rgba(18, 32, 65, 0.1);

    @media (max-width: 560px) {
      padding-top: 40px;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 30px;
      width: 95%;
    }
  }

  &__close-btn {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
  }

  &__content {
  }

  &-notification {
    &__title {
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 1em;
    }
    &__content {
      font-size: 18px;
      line-height: 1.5;

      p {
        margin-bottom: 0.5em;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .callback__form {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &-title {
      color: #484748;
      text-align: center;
      font-family: "Circe";
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
      width: 100%;
      display: block;
      margin-bottom: 10px;
    }

    &-subtitle {
      color: #414042;
      font-family: "Circe";
      display: block;
      font-size: 16px;
      font-style: normal;
      text-align: center;
      font-weight: 400;
      line-height: 100%;
    }

    &.popup .callback__form-label {
      position: relative;
      display: block;
      padding-left: 44px;
    }

    input[type="checkbox"] {
      display: none;

      & + label {
        position: relative;
        font-size: 14px;
        color: #3e3e3f;
        cursor: pointer;
        width: 80%;
      }
    }
  }
}
