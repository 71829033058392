@import "@/shared/ui/config/scss/_index";

.footerNav {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;

  @media (max-width: 768px) {
    gap: 25px;
  }

  &-column {
    &__title {
      margin-bottom: 10px;
      @media (max-width: 768px) {
        font-size: 30px;
      }
      @media (max-width: 560px) {
        font-size: 20px;
      }
    }
  }

  &-menu {
    &__link {
      @media (max-width: 768px) {
        font-size: 30px;
      }
      @media (max-width: 560px) {
        font-size: 20px;
      }
    }
  }
}
