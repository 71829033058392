@font-face {
  font-family: "Circe";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url($templateRootPath + "php/assets/fonts/circle/e44d72da66137f5822c7.woff2")
    format("woff2");
}

@font-face {
  font-family: "Circe";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url($templateRootPath + "php/assets/fonts/circle/537ca611fdf789932c76.woff2")
    format("woff2");
}

@font-face {
  font-family: "Circe";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url($templateRootPath + "php/assets/fonts/circle/a138ed3d89a54ab91348.woff2")
    format("woff2");
}

@font-face {
  font-family: "Circe";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url($templateRootPath + "php/assets/fonts/circle/c2eeb9a94d9498ac19fe.woff2")
    format("woff2");
}

@font-face {
  font-family: "Circe";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url($templateRootPath + "php/assets/fonts/circle/1a85d2f6ea13d1b2df6f.woff2")
    format("woff2");
}

/* Cera Pro */

@font-face {
  font-family: "Cera Pro";
  src: url($templateRootPath + "php/assets/fonts/cera-pro/CeraPro-Light.eot");
  src: local("Cera Pro Light"), local("CeraPro-Light"),
    url($templateRootPath + "php/assets/fonts/cera-pro/CeraPro-Light.eot?#iefix")
      format("embedded-opentype"),
    url($templateRootPath + "php/assets/fonts/cera-pro/CeraPro-Light.woff2")
      format("woff2"),
    url($templateRootPath + "php/assets/fonts/cera-pro/CeraPro-Light.woff")
      format("woff"),
    url($templateRootPath + "php/assets/fonts/cera-pro/CeraPro-Light.ttf")
      format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Cera Pro";
  src: url($templateRootPath + "php/assets/fonts/cera-pro/CeraPro-MediumItalic.eot");
  src: local("Cera Pro Medium Italic"), local("CeraPro-MediumItalic"),
    url($templateRootPath + "php/assets/fonts/cera-pro/CeraPro-MediumItalic.eot?#iefix")
      format("embedded-opentype"),
    url($templateRootPath + "php/assets/fonts/cera-pro/CeraPro-MediumItalic.woff2")
      format("woff2"),
    url($templateRootPath + "php/assets/fonts/cera-pro/CeraPro-MediumItalic.woff")
      format("woff"),
    url($templateRootPath + "php/assets/fonts/cera-pro/CeraPro-MediumItalic.ttf")
      format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Cera Pro";
  src: url($templateRootPath + "php/assets/fonts/cera-pro/CeraPro-RegularItalic.eot");
  src: local("Cera Pro Regular Italic"), local("CeraPro-RegularItalic"),
    url($templateRootPath + "php/assets/fonts/cera-pro/CeraPro-RegularItalic.eot?#iefix")
      format("embedded-opentype"),
    url($templateRootPath + "php/assets/fonts/cera-pro/CeraPro-RegularItalic.woff2")
      format("woff2"),
    url($templateRootPath + "php/assets/fonts/cera-pro/CeraPro-RegularItalic.woff")
      format("woff"),
    url($templateRootPath + "php/assets/fonts/cera-pro/CeraPro-RegularItalic.ttf")
      format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Cera Pro";
  src: url($templateRootPath + "php/assets/fonts/cera-pro/CeraPro-BlackItalic.eot");
  src: local("Cera Pro Black Italic"), local("CeraPro-BlackItalic"),
    url($templateRootPath + "php/assets/fonts/cera-pro/CeraPro-BlackItalic.eot?#iefix")
      format("embedded-opentype"),
    url($templateRootPath + "php/assets/fonts/cera-pro/CeraPro-BlackItalic.woff2")
      format("woff2"),
    url($templateRootPath + "php/assets/fonts/cera-pro/CeraPro-BlackItalic.woff")
      format("woff"),
    url($templateRootPath + "php/assets/fonts/cera-pro/CeraPro-BlackItalic.ttf")
      format("truetype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Cera Pro";
  src: url($templateRootPath + "php/assets/fonts/cera-pro/CeraPro-Medium.eot");
  src: local("Cera Pro Medium"), local("CeraPro-Medium"),
    url($templateRootPath + "php/assets/fonts/cera-pro/CeraPro-Medium.eot?#iefix")
      format("embedded-opentype"),
    url($templateRootPath + "php/assets/fonts/cera-pro/CeraPro-Medium.woff2")
      format("woff2"),
    url($templateRootPath + "php/assets/fonts/cera-pro/CeraPro-Medium.woff")
      format("woff"),
    url($templateRootPath + "php/assets/fonts/cera-pro/CeraPro-Medium.ttf")
      format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Cera Pro";
  src: url($templateRootPath + "php/assets/fonts/cera-pro/CeraPro-BoldItalic.eot");
  src: local("Cera Pro Bold Italic"), local("CeraPro-BoldItalic"),
    url($templateRootPath + "php/assets/fonts/cera-pro/CeraPro-BoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url($templateRootPath + "php/assets/fonts/cera-pro/CeraPro-BoldItalic.woff2")
      format("woff2"),
    url($templateRootPath + "php/assets/fonts/cera-pro/CeraPro-BoldItalic.woff")
      format("woff"),
    url($templateRootPath + "php/assets/fonts/cera-pro/CeraPro-BoldItalic.ttf")
      format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Cera Pro";
  src: url($templateRootPath + "php/assets/fonts/cera-pro/CeraPro-ThinItalic.eot");
  src: local("Cera Pro Thin Italic"), local("CeraPro-ThinItalic"),
    url($templateRootPath + "php/assets/fonts/cera-pro/CeraPro-ThinItalic.eot?#iefix")
      format("embedded-opentype"),
    url($templateRootPath + "php/assets/fonts/cera-pro/CeraPro-ThinItalic.woff2")
      format("woff2"),
    url($templateRootPath + "php/assets/fonts/cera-pro/CeraPro-ThinItalic.woff")
      format("woff"),
    url($templateRootPath + "php/assets/fonts/cera-pro/CeraPro-ThinItalic.ttf")
      format("truetype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Cera Pro";
  src: url($templateRootPath + "php/assets/fonts/cera-pro/CeraPro-LightItalic.eot");
  src: local("Cera Pro Light Italic"), local("CeraPro-LightItalic"),
    url($templateRootPath + "php/assets/fonts/cera-pro/CeraPro-LightItalic.eot?#iefix")
      format("embedded-opentype"),
    url($templateRootPath + "php/assets/fonts/cera-pro/CeraPro-LightItalic.woff2")
      format("woff2"),
    url($templateRootPath + "php/assets/fonts/cera-pro/CeraPro-LightItalic.woff")
      format("woff"),
    url($templateRootPath + "php/assets/fonts/cera-pro/CeraPro-LightItalic.ttf")
      format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Cera Pro";
  src: url($templateRootPath + "php/assets/fonts/cera-pro/CeraPro-Black.eot");
  src: local("Cera Pro Black"), local("CeraPro-Black"),
    url($templateRootPath + "php/assets/fonts/cera-pro/CeraPro-Black.eot?#iefix")
      format("embedded-opentype"),
    url($templateRootPath + "php/assets/fonts/cera-pro/CeraPro-Black.woff2")
      format("woff2"),
    url($templateRootPath + "php/assets/fonts/cera-pro/CeraPro-Black.woff")
      format("woff"),
    url($templateRootPath + "php/assets/fonts/cera-pro/CeraPro-Black.ttf")
      format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Cera Pro";
  src: url($templateRootPath + "php/assets/fonts/cera-pro/CeraPro-Bold.eot");
  src: local("Cera Pro Bold"), local("CeraPro-Bold"),
    url($templateRootPath + "php/assets/fonts/cera-pro/CeraPro-Bold.eot?#iefix")
      format("embedded-opentype"),
    url($templateRootPath + "php/assets/fonts/cera-pro/CeraPro-Bold.woff2")
      format("woff2"),
    url($templateRootPath + "php/assets/fonts/cera-pro/CeraPro-Bold.woff")
      format("woff"),
    url($templateRootPath + "php/assets/fonts/cera-pro/CeraPro-Bold.ttf")
      format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Cera Pro";
  src: url($templateRootPath + "php/assets/fonts/cera-pro/CeraPro-Regular.eot");
  src: local("Cera Pro Regular"), local("CeraPro-Regular"),
    url($templateRootPath + "php/assets/fonts/cera-pro/CeraPro-Regular.eot?#iefix")
      format("embedded-opentype"),
    url($templateRootPath + "php/assets/fonts/cera-pro/CeraPro-Regular.woff2")
      format("woff2"),
    url($templateRootPath + "php/assets/fonts/cera-pro/CeraPro-Regular.woff")
      format("woff"),
    url($templateRootPath + "php/assets/fonts/cera-pro/CeraPro-Regular.ttf")
      format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Cera Pro";
  src: url($templateRootPath + "php/assets/fonts/cera-pro/CeraPro-Thin.eot");
  src: local("Cera Pro Thin"), local("CeraPro-Thin"),
    url($templateRootPath + "php/assets/fonts/cera-pro/CeraPro-Thin.eot?#iefix")
      format("embedded-opentype"),
    url($templateRootPath + "php/assets/fonts/cera-pro/CeraPro-Thin.woff2")
      format("woff2"),
    url($templateRootPath + "php/assets/fonts/cera-pro/CeraPro-Thin.woff")
      format("woff"),
    url($templateRootPath + "php/assets/fonts/cera-pro/CeraPro-Thin.ttf")
      format("truetype");
  font-weight: 100;
  font-style: normal;
}
