@import "@/shared/ui/config/scss/_index";

.footer {
  background: #3e3e3f;
  padding-top: 70px;
  padding-bottom: 40px;
  @media (max-width: 768px) {
    padding-top: 92px;
  }

  &__top-box {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: 30px;
    border-bottom: 1px solid #f4f4f4;
    flex-wrap: wrap;
    gap: 50px;
    @media (max-width: 768px) {
      padding-bottom: 0;
      border-bottom: none;
      gap: 25px;
    }
  }

  &__left-box {
    width: 470px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    @media (max-width: 1045px) {
      width: 100%;
    }
    @media (max-width: 768px) {
      gap: 25px;
    }
  }

  &__wrap-box {
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 560px) {
      margin-top: 5px;
    }
  }

  &__logo {
    display: flex;
    @media (max-width: 768px) {
      margin-bottom: 20px;
    }
  }

  &__logo-img {
  }

  &__link {
    display: block;
    color: #fff;
    font-family: "Circe";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
    @media (max-width: 768px) {
      font-size: 22px;
    }
    @media (max-width: 560px) {
      font-size: 20px;
    }

    &:hover {
      text-decoration: underline;
    }

    &_email {
      text-decoration: underline;
      text-align: left;
      &:hover {
        text-decoration: none;
      }
    }
  }

  &__copyright {
  }

  &__title {
    display: block;
    color: #fff;
    font-family: "Circe";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration: none;
  }

  &__text {
    display: block;
    color: #fff;
    font-family: "Circe";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
  }

  &__right-box {
    width: 490px;
    display: flex;
    flex-direction: column;
    gap: 70px;
    @media (max-width: 1045px) {
      width: 100%;
      flex-direction: row;
      flex-wrap: wrap;
    }
    @media (max-width: 768px) {
      gap: 35px;
    }
  }

  &__contacts {
    display: flex;
    flex-wrap: wrap;
    gap: 65px;
    @media (max-width: 768px) {
      gap: 45px;
    }
    @media (max-width: 560px) {
      gap: 25px;
    }
  }

  &__bottom-box {
    padding-top: 35px;
    @media (max-width: 768px) {
      padding-top: 75px;
    }
  }

  &__wrap-socials {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
  }

  &__social-item {
    &:hover {
      svg path {
        fill: #fcc649;
      }
    }

    svg path {
      transition: all 0.3s;
    }
  }
}
