@import "@/shared/ui/config/scss/_index";

// @import "@/shared/ui/button/index";
// @import "@/entities/webform/index";

.sidebar-form {
  margin-top: 25px;

  &__form {
  }

  &__form-input {
    color: #979797;
    font-family: "Circe";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0 5px 7px 0;
    border: none;
    border-bottom: 1px solid #bfbfbf;
    display: flex;
    margin-bottom: 18px;
    width: 100%;
    max-width: 240px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__form-button {
    margin-top: 25px;
    width: 100%;
    max-width: 372px;
  }
}
