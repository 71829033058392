@import "@/shared/ui/config/scss/_index";

// @import "@/features/headerSearch/index";

.header {
  display: flex;
  flex-direction: column;
  padding: 25px 0 17px;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  transition: all 0.3s;
  background-color: #ffffff80;

  @media (max-width: 768px) {
    // background: #fff;
  }

  &_small {
    background: #fff;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
    padding: 15px 0 17px;

    .header__wrapper {
      margin-bottom: 0;
    }

    .header__wrap {
      gap: 10px;
    }
    .header__wrap-right {
      gap: 40px;
    }

    .header__logo-desktop {
      display: none;
    }

    .header__logo-mobile {
      display: block;
    }
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 100%;

    @media (max-width: 768px) {
      margin-bottom: 0;
    }
  }

  &__wrap {
    position: relative;
    display: flex;
    align-items: center;
    gap: 30px;
    @media (max-width: 560px) {
      gap: 15px;
    }
  }

  &__burger {
    display: block;
  }

  &__logo {
  }

  &__logo-desktop {
    width: 195px;
    height: auto;
    @media (max-width: 560px) {
      display: none;
    }
  }

  &__logo-mobile {
    display: none;
    min-width: 43px;
    min-height: 35px;
    @media (max-width: 560px) {
      display: block;
    }
  }

  &__wrap-right {
    gap: 50px;
    width: 100%;
    justify-content: flex-end;
  }
}
