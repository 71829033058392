@import "./_index";

@import "./reset";
@import "./fonts";
@import "./helpers";

/* общие стили для всех страниц*/
* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  font-family: "Circe";
  font-style: normal;
  font-weight: 400;
  font-size: 1.2vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.arrow__box {
  cursor: pointer;
  display: flex;
  gap: 16px;

  .slider-button {
    width: 24px;
    height: 24px;

    @media (max-width: 950px) {
      width: 53px;
      height: 53px;
    }

    @media (max-width: 768px) {
      width: 24px;
      height: 24px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }
}

h1 {
  color: $color-text-inverse;
  font-family: "Circe";
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;

  @media (max-width: 768px) {
    font-size: 36px;
  }

  &.title-dark {
    color: $color-text-light;
  }

  &.title-page {
    margin-bottom: 30px;
    font-weight: 400;

    @media (max-width: 768px) {
      text-align: center;
    }
  }
}

h2 {
  color: #414042;
  text-align: center;
  font-size: 56px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: 0.56px;

  @media (max-width: 950px) {
    font-size: 60px;
  }
  @media (max-width: 768px) {
    font-size: 35px;
  }

  &.title-section {
    font-size: 30px;
    margin-bottom: 15px;
    font-weight: 700;
    @media (max-width: 768px) {
      font-size: 26px;
      text-align: center;
    }
  }
}

.section-box {
  position: relative;

  &::before {
    content: " ";
    background-image: url($srcSharedImagesPath + "decor/decor-grey.svg");
    background-repeat: no-repeat;
    position: absolute;
    background-size: cover;
    background-position: top right;
    width: 100%;
    height: calc(100% + 100px);
    top: -100px;
    right: 0px;
    z-index: -50;

    @media (max-width: 1140px) {
      top: 0;
    }
  }
}

.container {
  max-width: 1332px;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 950px) {
    padding-left: 25px;
    padding-right: 25px;
  }
}

ul {
	display: flex;
	flex-direction: column;
	gap: 3px;
}

ul li {
	display: flex;
	align-items: flex-start;
	gap: 15px;
	color: #363636;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

ul li::before {
	content: " ";
	display: flex;
	background: #363636;
	border-radius: 50%;
	min-width: 4px;
	min-height: 4px;
	width: 4px;
	height: 4px;
	margin-top: 12px;
}

ol {
	list-style: auto;
	list-style-position: inside;
	margin-bottom: 10px;
}

ol li {
	margin-top: 3px;
	color: #363636;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}