@import "@/shared/ui/config/scss/_index";

.form-fields-group {
  &_grid_2 {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 24px;
    & > input,
    & > div {
      width: calc(50% - 12px);
    }
  }
}

.wf-hp-f {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
}
