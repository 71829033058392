@import "@/shared/ui/config/scss/_index";

.sidebarNav {
  &__block {
    padding-bottom: 20px;
    padding-top: 20px;
    position: relative;
    // border-top: 1px solid #bfbfbf;

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      content: " ";
      display: block;
      height: 1px;
      width: 100%;
      max-width: 240px;
      background: #bfbfbf;
    }

    &:first-of-type {
      &:before {
        content: " ";
        display: none;
      }
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__item {
    &.active {
      .sidebarNav__sublist {
        display: flex;
      }
      .sidebarNav__link-arrow {
        transform: rotateZ(-180deg);
      }
    }
  }

  &__link {
    color: #414042;
    font-family: "Circe";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 10px;
    transition: all 0.3s;
    cursor: pointer;

    &:hover {
      color: #000;
    }
    @media (max-width: 950px) {
      font-size: 24px;
    }
    @media (max-width: 768px) {
      // max-width: 587px;
      font-size: 18px;
    }
  }

  &__link-arrow {
    transition: all 0.3s;
  }

  &__sublist {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-left: 17px;

    display: none;
  }

  &__subitem {
  }

  &__sublink {
    color: rgba(65, 64, 66, 0.7);
    font-family: "Circe";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 0%; /* 0px */
    transition: all 0.3s;
    text-decoration: none;

    &:hover {
      color: #414042;
    }

    &_bold {
      font-weight: 700;
    }

    @include media-breakpoint-down(md) {
      line-height: 120%;
    }
  }
}
