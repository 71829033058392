@import "@/shared/ui/config/scss/_index";

/* form-search start */
.search {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  right: 0;
  width: 40px;
  height: 42px;
  background-color: transparent;
  border-radius: 30px;
  overflow: hidden;
  z-index: 1;
  border: 1px solid transparent;
  -webkit-transition: width 0.3s linear, border 0.1s linear;
  -o-transition: width linear 0.3s, border linear 0.1s;
  transition: width 0.3s linear, border 0.1s linear;

  &::-webkit-search-cancel-button {
    position: relative;
    right: 20px;
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    border-radius: 10px;
    background: red;
  }

  &.open {
    position: absolute;
    width: 50%;
    border: 1px solid #d1d1d1;
    @media (min-width: 481px) and (max-width: 767px) {
      width: calc(100% - 55px);
    }

    @media (min-width: 481px) and (max-width: 767px) {
      width: calc(100% - 55px);
    }

    @media (max-width: 480px) {
      width: 102%;
    }

    .search__field {
      background: #fff;
    }
    .search__btn {
      right: 10px;
    }
  }

  &__btn {
    display: block;
    position: absolute;
    top: 0px;
    right: -7px;
    width: 40px;
    font-size: 0;
    z-index: 1;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  &__field,
  &__btn {
    height: 40px;
    height: 100%;
    background: transparent;
    border: none;
  }

  &__field {
    -webkit-appearance: none;
    display: block;
    width: 100%;
    line-height: 1rem;
    padding: 20px;
    font-size: 1rem;
    outline: none;
    -webkit-transition: background 0.2s linear;
    -o-transition: background linear 0.2s;
    transition: background 0.2s linear;

    &::-webkit-search-cancel-button {
      position: relative;
      right: 5px;
      -webkit-appearance: none;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox=0 0 21 20 fill=none xmlns=http://www.w3.org/2000/svg%3E%3Cpath fill=%23E0E0E0 d=M.963 2.479L3.295.142l17.492 17.523L18.455 20z/%3E%3Cpath fill=%23E0E0E0 d=M18.28 0l2.331 2.336L3.12 19.858.787 17.522z/%3E%3C/svg%3E");
      cursor: pointer;
    }
  }
}

/* form-search end */
