@import "@/shared/ui/config/scss/_index";

/* menu-nav start */
.nav {
  font-size: 18px;
  @media (max-width: 1350px) {
    display: none;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    align-items: center;
    gap: 2px 15px;
    padding: 5px 20px 5px 20px;
    border-radius: 24px;
    background: rgba(255, 255, 255, 0.5);
    @media (max-width: 768px) {
      display: none;
    }
  }

  &__item {
    padding: 4px 15px;
    transition: background-color 0.3s;
    border-radius: 24px;
    position: relative;

    &:hover {
      background-color: rgba(62, 62, 63, 0.05);
    }
    &.active {
      &:hover {
        background-color: transparent;
      }

      .nav__sublist {
        display: grid;
      }

      .nav__item-link {
        color: #ea6333;
      }
    }
  }

  &__item-link {
    background-color: transparent;

    text-decoration: none;
    color: #484748;
    font-family: "Cera Pro";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
  }

  &__sublist {
    display: none;
    position: absolute;
    border-radius: 15px;
    background: #fff;
    box-shadow: 0px 4px 20px 0px rgba(69, 89, 142, 0.1);
    padding: 50px 40px;
    left: 50%;
    top: 50px;
    transform: translateX(-50%);
    max-width: 100vw;
    width: 670px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-column-gap: 40px;
    grid-row-gap: 25px;
    max-height: 80vh;
    overflow-y: auto;

    &_device {
      width: 800px;
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    &_spare {
      width: 285px;
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }

  &__wrap-sublist {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-column-gap: 40px;
    grid-row-gap: 25px;
  }

  &__sublink-review-all {
    color: #f0887c;
    font-weight: 700;
    font-size: 18px;
    line-height: 105%;
    display: block;
    margin-top: 20px;

    &:hover {
      text-decoration: none;
    }
  }

  &__subitem {
  }

  &__sublink {
    color: #484748;
    font-family: "Circe";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;

    text-decoration: none;
    transition: all 0.3s;
    display: flex;
    align-items: center;
    gap: 10px;

    &:hover {
      color: #000;
    }
  }

  &__sublink-img {
    width: 40px;
    height: 40px;
    min-width: 40px;

    use {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.header_small {
  .nav {
    position: absolute;
    /* bottom: 0; */
    top: 50%;
    margin: auto;
    transform: translateY(-50%) translateX(-50%);
    left: 46%;
    /* right: 0; */
    font-size: 14px !important;
    min-width: 680px;
    width: max-content;
    @media (max-width: 1100px) {
      min-width: auto;
    }
  }

  .nav__list {
    gap: 2px 5px !important;
  }

  .nav__item {
    padding: 8px !important;
  }
  .nav__item-link {
    font-size: 14px;
  }
}
/* menu-nav end */
