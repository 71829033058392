@import "@/shared/ui/config/scss/_index";

.entity-phones-header {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;

  .header_small & {
    width: 100px;
    gap: 0;
  }

  .header__phone {
    color: #414042;
    text-align: right;
    font-size: 20px;
    line-height: normal;
    text-decoration: none;

    .header_small & {
      line-height: 1.1;
    }

    &-text {
      .header_small & {
        font-size: 14px;
      }
    }
  }
}
